import React from "react";
import {isMobile, isIOS, isChrome} from "react-device-detect";
import Circle from '../components/ui/circle';
import WarningNotice from '../components/ui/notices/warning';


const Welcome: React.FC = () => {

  return (
    <div style={{width: '90%', margin: '2rem auto'}}>

      {
        (isMobile && isIOS && isChrome) &&
          <WarningNotice message='Please open this in Safari to allow saving this application to your home screen.' />
      }
      
      <Circle text="1" customStyles={{margin: '0 auto 0.5rem'}} />
      <div style={{textAlign: 'center', margin: '0 0 0.5rem 0'}}><strong>Add To Your Homepage</strong></div>

      <div style={{fontSize: '0.75rem', textAlign: 'center'}}>For the best experience, please go ahead and add this to your homepage. Not sure how? Watch the video below.</div>

      <iframe title="how to add application to homescreen" style={{display: 'block', width: '100%', maxWidth: '600px', height: '25vh', margin: '1rem auto'}} src="https://www.youtube.com/embed/cGWWNjDX8ho" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <Circle text="2" customStyles={{margin: '1rem auto 0.5rem'}} />
      <div style={{textAlign: 'center'}}><strong>Launch the App</strong></div>
      <div style={{fontSize: '0.75rem', textAlign: 'center'}}>Launch the SABMR App you just saved on your device</div>

      <Circle text="3" customStyles={{margin: '1rem auto 0.5rem'}} />
      <div style={{textAlign: 'center'}}><strong>Complete the Short Pre-Qualification Questionnaire</strong></div>
      <div style={{fontSize: '0.75rem', textAlign: 'center'}}>With less than 10 questions it should take you only 2min.</div>

    </div>
  );

}

export default Welcome;
import React  from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import {ApplicantFormModel} from '../../models';
import { validateMobileNumber, validateEmailAddress, 
  bmiCalculator, geodataCityByProvince} from '../../utils/helpers';
import {db} from '../../utils/database';

interface Props {
  formSubmissionHandler: any,
  formSubmitting: boolean,
  offline?: boolean
}

interface State {
  isFormValid: boolean,
  regret: any
};

const geodata: any = geodataCityByProvince();
const provinces: any = Object.keys(geodata);

const diseases = [
  'Heart disease (e.g. previous heart attack, coronary heart disease, heart surgery)',
  'Lung diseases (e.g. Chronic Obstructive Pulmonary disease, TB)',
  'Diseases of the haematopoietic system',
  'Blood disorders (e.g. Thalassaemia Major, Protein C, Protein S or Antithrombin deficiency, Fanconi Anaemia)',
  'Severe kidney diseases',
  'Thrombosis',
  'Neurological disorders (e.g. Epilepsy, Parkinson’s disease)',
  'Cancer',
  'Autoimmune conditions (e.g. Rheumatoid Arthritis, Crohn’s disease, Thyroid Disease)',
  'Infectious diseases (e.g. HIV or AIDS, Hepatitis B and C)',
  'Diabetes Type 1 and 2 (except your Diabetes Type 2 is controlled by diet and there are no other risk factors)',
  'A carrier of a gene for a genetic disease',
  'Previous brain or heart surgery',
  'Malaria treatment during the last two years/ current'
];

const OnboardingForm: React.FC<Props> = ({formSubmissionHandler, formSubmitting, offline}) => {

  let formData: ApplicantFormModel = {
    firstName     : '',
    lastName      : '',
    mobileNumber  : undefined,
    emailAddress  : '',
    birthday      : '',
    weight        : '',
    height        : '',
    gender        : '',
    ethnicity     : '',
    age           : undefined,
    province      : '',
    city          : '',
    agegroup      : '',
    diseases      : '',
    bmi           : '',
    qualified     : 'true',
    drm_code      : undefined,
    kit_code      : undefined,
    regret        : undefined
  }

  const [fields, setFields] = React.useState(formData);

  const birthdayField: any = React.createRef();

  React.useEffect( () => {
    //console.log('KICKING OFF....'); // <------ testing purposes only, can be removed!
    db.applicant.get(1, (row)=>{
      if(row) setFields(row);
    });
  }, []);

  React.useEffect( () => {
    //console.log('fields Updated: ', fields); // <------ testing purposes only, can be removed!
    db.transaction('rw', db.applicant, async() => {
      db.applicant.update(1, fields);
    });
  }, [fields]);


  const setAgeGroup = (age: number) => {
    
    const minAge = process.env.REACT_APP_MIN_AGE ? parseInt(process.env.REACT_APP_MIN_AGE) : 0;
    const maxAge = process.env.REACT_APP_MAX_AGE ? parseInt(process.env.REACT_APP_MAX_AGE) : 0;
   
    const agegroup = (age >= minAge && age <= maxAge) ? 'yes' : 'no';

    setFields({ 
      ...fields, 
      agegroup
    }); 
  }

  const setBMI = () => {
    
    const weight: any = fields.weight;
    let height: any = fields.height;

    if(!weight || !height) return;
    
    // Convert CM to M
    height = height/100;

    const bmi = bmiCalculator(weight, height);
    // console.log('BMI: ', bmi); <------ testing purposes only, can be removed!

    setFields({ 
      ...fields, 
      bmi
    }); 
  }

  const setQualified = () => {

    const minWeight = process.env.REACT_APP_MIN_WEIGHT ? process.env.REACT_APP_MIN_WEIGHT : 0;
    const maxBmi = process.env.REACT_APP_MAX_BMI ? process.env.REACT_APP_MAX_BMI : 0;

    let qualified = true;
    let regrets = [];
   
    // must be within the age group
    let ageGroupRequirement = fields.agegroup === 'yes';
    qualified = qualified ? ageGroupRequirement : false;
    
    if(!ageGroupRequirement){
      regrets.push('Failed on age');
    }
      
    // (weight less than or equal 49kg or bmi more than 40)\
    let weightBmiRequirements = (fields.weight > minWeight && fields.bmi < maxBmi);
    qualified = qualified ? weightBmiRequirements : false;
    
    if(!weightBmiRequirements){
      const regretType = fields.weight < minWeight ? 'Failed on weight' : 'Failed on bmi';
      regrets.push(regretType);
    }

    // no diseases
    let diseaseRequirement = fields.diseases === 'no' ;
    qualified = qualified ? diseaseRequirement: false;
    
    if(!diseaseRequirement){
      regrets.push('Failed on diseases');
    }

    const status = qualified ? 'true' : 'false';

    setFields({ 
      ...fields, 
      qualified: status,
      regret: regrets.join(', ')
    });

  }

  /**
   * Error messages in order of relevance
   * @param field 
   * @param value 
   */
  const validationRules = (field: string, value: any) => {
    
    let message = '';

    switch(field) {

      case 'firstName':
        message = (value === '') ? 'First name is required' : '';
        break;

      case 'lastName':
        message = (value === '') ? 'Last name is required' : '';
        break;
      
      case 'mobileNumber':
        message = validateMobileNumber(value) ? '' : 'Invalid mobile number';
        message = (value === null) ? 'Mobile number is required' : message;
        break;
      
      case 'emailAddress':
        message = validateEmailAddress(value) ? '' : 'Invalid email address';
        message = (value === '') ? 'Email address is required' : message;
        break;
      
      case 'weight':
        message = (value === '') ? 'Please enter your weight (kilograms)' : message;
        break;
      
      case 'height':
        message = (value === '') ? 'Please enter your height (centimeters)' : message;
        break;
      
      case 'gender':
        message = (value === '') ? 'Please select your gender' : '';
        break;

      case 'ethnicity':
        message = (value === '') ? 'Please select your ethnicity' : '';
        break;

      case 'age':
        message = (value === null || isNaN(value) ) ? 'Please enter your age' : message;
        break;
      
      case 'province':
        message = (value === '') ? 'Please select your province' : '';
        break;

      case 'city':
        message = (value === '') ? 'Please select your city' : '';
        break;
      
      case 'diseases':
        message = (value === '') ? 'Please select any diseases (if not applicable, select N/A)' : '';
        break;

      default:
        // Nothing
    }

    return message;

  }

  const validateForm = () => {

    const fieldsData = Object.keys(formData) as Array<keyof ApplicantFormModel>;

    // notice last param on reduce method, required for typescript to not give errors
    const errors = fieldsData.reduce( (accumulator: any, field) => {
      const value = fields[field];
      const errorMessage = validationRules(field, value);
      
      // console.log('errorMessage: ', errorMessage); <------ testing purposes only, can be removed!
      
      if(errorMessage){
        accumulator.push(`\n${errorMessage}`);
      }

      return accumulator;

    }, []);

    // console.log('errors: ', errors); <------ testing purposes only, can be removed!
    
    if(errors.length > 0){
      alert(errors);
      return false;
    }
    else {
      formSubmissionHandler(fields);
    }
  }

  const fieldRightText: Object = {
    position: 'absolute',
    top: '0',
    right: '2rem',
    lineHeight: '2.25rem',
  }

  const fieldLeftText: Object = {
    position: 'absolute',
    top: '0',
    left: '1.5rem',
    lineHeight: '2.25rem',
  }

  return (
    <Form className="container">
        
        <Row>
          
          <Col xs={6} className="mb-2">
            <Form.Control name="first_name" autoComplete="off" placeholder="First Name" type="text"
              value={fields.firstName} onChange={ (e) => {
                const value = e.target.value;
                setFields({ ...fields, firstName: value }); 
              } }
            />
          </Col>
          
          <Col xs={6} className="mb-2">
            <Form.Control name="Last_name" autoComplete="off" placeholder="Last Name" type="text"
              value={fields.lastName} onChange={ (e) => { 
                const value = e.target.value;
                setFields({ ...fields, lastName: value }); } }
            />
          </Col>
        
          <Col xs={6} className="mb-2">
            <div style={fieldLeftText}>+27</div>
            <Form.Control name="mobile_number" autoComplete="off" placeholder="Mobile" type="text"
                style={{ padding: '0 0 0 2.8rem'}}
                value={fields.mobileNumber ? fields.mobileNumber : '' } onChange={ (e) => {
                  setFields({ ...fields, mobileNumber: parseInt(e.target.value) }); } }
            />
          </Col>

          <Col xs={6} className="mb-2">
            <Form.Control name="email_address" autoComplete="off" placeholder="Email Address" type="text"
              value={ fields.emailAddress } onChange={ (e) => {
                setFields({ ...fields, emailAddress: e.target.value } ); } }
            />
          </Col>
  
          <Col xs={6} className="mb-2">       
            <Form.Control name="weight" autoComplete="off" placeholder="Weight" type="text"
                onBlur={(e:any) => {
                  // convert to kg -> grams
                  setBMI();
                }}
                value={ fields.weight ? fields.weight : '' } onChange={ (e) => {
                  setFields({ ...fields, weight: parseInt(e.target.value) }); } }
            />
            <div style={fieldRightText}>kg</div>
          </Col>

          <Col xs={6} className="mb-2"> 
            <Form.Control name="height" autoComplete="off" placeholder="Height" type="text"
              onBlur={(e:any) => {
                // convert to cm -> m (formula - m=cm/100)
                setBMI();
              }}
              value={ fields.height ? fields.height : '' } onChange={ (e) => {
                setFields({ ...fields, height: parseInt(e.target.value) } ); } }
            />
            <div style={fieldRightText}>cm</div>
          </Col>

          <Col xs={6} className="mb-2">
            <Form.Control name="gender" as="select" value={ fields.gender } onChange={ (e) => {
                setFields({ ...fields, gender: e.target.value }); } }
            >
              <option value=''>Gender...</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </Form.Control>
          </Col>

          <Col xs={6} className="mb-2">
            <Form.Control name="ethnicity" as="select" value={ fields.ethnicity } onChange={ (e) => {
                setFields({ ...fields, ethnicity: e.target.value }); } }
            >
              <option value=''>Ethnicity...</option>
              <option value='Asian/Indian'>Asian/Indian</option>
              <option value='Black'>Black</option>
              <option value='Coloured'>Coloured</option>
              <option value='White'>White</option>
              <option value='Other/Unknown'>Other/Unknown</option>
            </Form.Control>
          </Col>

          <Col xs={6} className="mb-2">
            <Form.Control ref={birthdayField} name="birthday" autoComplete="off" placeholder="Date of birth" type="text"
              value={ fields.birthday ? fields.birthday : '' } 
              onChange={ (e) => {
                setFields({ ...fields, birthday: e.target.value });
              }}
              onFocus={ () => {
                birthdayField.current.type='date';
              }}
              onBlur={ () => {
                if(fields.birthday === '')
                  birthdayField.current.type='text';
              }}
            />
          </Col>

          <Col xs={6} className="mb-2"> 
            <Form.Control name="age" autoComplete="off" placeholder="Age" type="number"
              onBlur={(e:any) => {
                setAgeGroup(fields.age ? fields.age : 0);
              }}
              value={ fields.age ? fields.age : '' } onChange={ (e) => {
                setFields({ ...fields, age: parseInt(e.target.value) } ); } }
            />
          </Col>

          <Col xs={6} className="mb-2">
            <Form.Control name="province" as="select" value={ fields.province } onChange={ (e) => {
                setFields({ ...fields, province: e.target.value }); } }
                onBlur={(e:any) => {
                  //console.log('province; ' , fields.province); <------ testing purposes only, can be removed!
                  //console.log('Cities; ' , geodata[fields.province]); <------ testing purposes only, can be removed!
                }}
            >
              <option value=''>Province...</option>
              { 
                  provinces.map( (province: any) => (
                    <option value={province} key={province}>{province}</option>
                  ))
              }
            </Form.Control>
          </Col>

          <Col xs={6} className="mb-2">
            <Form.Control name="city" as="select" value={ fields.city } onChange={ (e) => {
                setFields({ ...fields, city: e.target.value }); } }
            >
              <option value=''>City...</option>
              { 
                  fields.province && geodata[fields.province].map( (city: any) => (
                    <option value={city} key={city}>{city}</option>
                  ))
              }
            </Form.Control>
          </Col>

          <Col xs={12} className="mb-2">
            <hr />
            <p>Do you have or did you previously have one of the following (chronic) diseases?</p>
            <Form.Control name="diseases" as="select" value={ fields.diseases } onChange={ (e) => {
                setFields({ ...fields, diseases: e.target.value }); } }
                onBlur={(e:any) => {
                  setQualified(); // Run when leaving the last required field
                }}
            >
              <option value=''>Select...</option>
              {
                  diseases.map(disease => (
                      <option value='yes' key={disease}>{disease}</option>
                  ))
              }
              <option value='no'>None</option>
            </Form.Control>
          </Col>

        </Row>

      <Button id="SubmitButton" variant="primary" type="submit" value="submit"
        style={{margin: '1rem 0 0'}}
        onClick={ e => {
          e.preventDefault();
          // formSubmissionHandler(fields); return; // <-------- Testing can be removed
          validateForm();
        } } 
        disabled={formSubmitting || offline}
      >
        { formSubmitting ? 'Submitting...' : 'Submit' }
      </Button>

    </Form>
  )
}

export default OnboardingForm;

import React from "react";
import QrReader from 'react-qr-reader';
import apiService from '../services/apiService';
import {validateURL} from '../utils/helpers';
import {ApplicantKitLinkModel} from '../models';
import {db} from '../utils/database';
import OfflineNotice from '../components/ui/notices/offline';

interface Props {
  offline?: boolean
}

const Kit: React.FC<Props> = ({offline}) => {

  const dataModel: ApplicantKitLinkModel = {
    recruitmentId :undefined,
    kitId: undefined 
  }

  const [scanResult, setScanResult] = React.useState('');
  const [formData, setFormData] = React.useState(dataModel);
  const [submitting, setSubmitting] = React.useState(false);
  const [applicant, setApplicant] = React.useState(false);
  const [qualified, setQualified] = React.useState(false);
  const [linked, setLinked] = React.useState(false);

  React.useEffect( () => {
    db.applicant.get({id:1}, (row)=>{
      
      if(row){

        if(row.drm_code){
          setApplicant(true)
        }

        if(row.qualified){
          setQualified(row.qualified === 'true')
        }

        if(row.kit_code){
          setLinked(true);
          return;
        }

        setFormData({
          ...formData,
          recruitmentId: row.drm_code
        });

      }
      
    });
  }, []);

  React.useEffect( () => {
    //console.log('formData: ', formData) ;<------ testing purposes only, can be removed!
    if(formData.recruitmentId && formData.kitId){
      processLinkKitToApplicant();
    }
  }, [formData]);

  React.useEffect(() => {
    
    //console.log('Scan Result: ', scanResult); <------ testing purposes only, can be removed!

    if(scanResult){
      if( validateURL(scanResult) ){
        window.open(scanResult, '_blank', 'noopener, noreferrer');
      }
      else if( validateKitCode(scanResult) ){
        setFormData({
          ...formData,
          kitId: scanResult
        });
      }
      else{
        alert(scanResult);
      }
    }

  }, [scanResult]);

  const handleScan = (data: any) => {
    if (data) {
      setScanResult(data);
    }
  }

  const handleError = (err: Error) => {
    // console.error(err) <------ testing purposes only, can be removed!
  }

  const validateKitCode = (code: string) => {
    return code.includes('SABMRDROKIT_');
  }

  const processLinkKitToApplicant = async () => {
    
    // API CALL
    // UPDATE LOCAL DB WITH RESULT
    //alert('API CALL TO LINK KIT CAN HAPPEN ---->');

    setSubmitting(true);

    const response = await apiService.updateApplicantWithKit(formData);

    if(response.status === 200){
      
      if(response.data.status === 'error'){
        alert(response.data.errors);
        setScanResult('');
        setLinked(false);
        setSubmitting(false);
        return false;
      }

      if(response.data.status === 'success'){
        db.transaction('rw', db.applicant, async() => {
          db.applicant.update(1, {kit_code: formData.kitId});
        });
        setLinked(true);
      }
    
    }
    
    setSubmitting(false);
  }

  const noApplicantStyle: any = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 16rem)',
      position: 'absolute',
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%'
  }

  return (
    <div style={{'position': 'relative', 'zIndex': 1000, width: '90%', margin: '2rem auto'}}>

      {
        offline &&
          <OfflineNotice message='You will only be able to scan the QR code found on your buccal swab kit once you have internet connectivity.' />
      }
      
      { 
        applicant && qualified && !linked &&
          <div>
            <h5 style={{'margin': '2vh 0', 'textAlign': 'center'}}>Congratulations</h5>
            <p style={{fontSize: '0.75rem'}}>Your prequalification has been successful. Please allow access to your camera and then scan the QR code found on your test kit. This will link your kit to your application.</p>
          </div>
      }

      { 
        !applicant &&
          <div style={noApplicantStyle}>
            <i className="fas fa-info-circle" style={{fontSize: '5rem', padding: '0 0 2rem', color: '#ffcd5a'}}></i>
            <p>You need to complete and successfully submit your pre-qualification form before you can scan and link your kit! </p>
          </div>
      }
      
      {
        (!submitting && !linked && applicant && qualified) &&
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      }
      {
        submitting &&
        <h3 style={{textAlign: "center", marginTop: '20vh'}}>loading...</h3>
      }

      {
        linked &&
        <div style={{
            ...noApplicantStyle,
            height: offline ? 'calc(80vh - 16rem)' :'calc(100vh - 16rem)'
        }}>
          <i className="fas fa-check-circle" style={{fontSize: '5rem', padding: '0 0 1rem', color: '#8bc34a'}}></i>
          <h5 style={{'margin': '2vh 0', 'textAlign': 'center'}}>Congratulations</h5>
          <p style={{fontSize: '0.75rem'}}>Your kit has been successfully linked with your application.</p>
        </div>
      }

      {
        applicant && !qualified &&
          <div style={{
            ...noApplicantStyle,
            height: offline ? 'calc(80vh - 16rem)' :'calc(100vh - 16rem)'
            }}
          >
            <i className="fas fa-exclamation-circle" style={{fontSize: '5rem', padding: '0 0 2rem', color: 'rgb(241 85 49)'}}></i>
            <p style={{fontSize: '0.75rem'}}>Unfortunately you do not meet the necessary requirements to become a stem cell donor.  However, you could be most helpful to the SABMR by becoming a financial donor instead. Would you like to make a once-off financial donation?</p>
            <p style={{padding: '1.5rem 0'}}><a href="https://sabmr.co.za/donate/" className="btn btn-success" style={{color: '#fff'}}  target="_blank" rel="noreferrer">I want to to help with a once-off donation</a></p>
            <p>Please follow us on <a href="https://www.facebook.com/sabonemreg/" target="_blank" rel="noreferrer">Facebook</a> and <a href="https://www.instagram.com/sabonemreg/" target="_blank" rel="noreferrer">Instagram</a> to stay connected.</p>
            <p style={{fontSize: '0.65rem', margin: '1.5rem 0 0'}}>If you’d like more information on why you were deferred, please have a look at our <a href="#">donor exclusion criteria</a>.</p>
        </div>
      }

    </div>
  );

}

export default Kit;
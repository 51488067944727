import {db} from './database';
import {ApplicantFormModel} from '../models';

export const dbInitAndSetSource = () => {
    
  const url     = new URL((window as any).window.location);
  const params  = new URLSearchParams(url.search);

  const sourceData = {
    source: params.get('source'),
    source_province: params.get('province'),
    source_city: params.get('city'),
    source_start: params.get('start'),
    source_end: params.get('end')
  }

  let formData: ApplicantFormModel = {
    firstName     : '',
    lastName      : '',
    mobileNumber  : undefined,
    emailAddress  : '',
    birthday      : '',
    weight        : '',
    height        : '',
    gender        : '',
    ethnicity     : '',
    age           : undefined,
    province      : '',
    city          : '',
    agegroup      : '',
    diseases      : '',
    bmi           : '',
    qualified     : 'true',
    drm_code      : undefined,
    kit_code      : undefined,
    source        : sourceData,
    regret        : undefined
  }

  db.transaction('rw', db.applicant, async() => {
    db.applicant.count( async (count) => {
      if(count === 0){
        await db.applicant.add(formData);
      }
    });
  });

}
export const geodata = [
  {
      "city": "Johannesburg", 
      "admin": "Gauteng", 
      "country": "South Africa", 
      "population_proper": "2026469", 
      "iso2": "ZA", 
      "capital": "admin", 
      "lat": "-26.205171", 
      "lng": "28.049815", 
      "population": "3435000"
  }, 
  {
      "city": "Cape Town", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "2432858", 
      "iso2": "ZA", 
      "capital": "primary", 
      "lat": "-33.925839", 
      "lng": "18.423218", 
      "population": "3215000"
  }, 
  {
      "city": "Benoni", 
      "admin": "Gauteng", 
      "country": "South Africa", 
      "population_proper": "605344", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.190851", 
      "lng": "28.311338", 
      "population": "2986000"
  }, 
  {
      "city": "Durban", 
      "admin": "KwaZulu Natal", 
      "country": "South Africa", 
      "population_proper": "2729000", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-29.857896", 
      "lng": "31.029198", 
      "population": "2729000"
  }, 
  {
      "city": "Pretoria", 
      "admin": "Gauteng", 
      "country": "South Africa", 
      "population_proper": "1338000", 
      "iso2": "ZA", 
      "capital": "primary", 
      "lat": "-25.706944", 
      "lng": "28.229444", 
      "population": "1338000"
  }, 
  {
      "city": "Vereeniging", 
      "admin": "Gauteng", 
      "country": "South Africa", 
      "population_proper": "474681", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.673133", 
      "lng": "27.926147", 
      "population": "1074000"
  }, 
  {
      "city": "Port Elizabeth", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "640054", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.917988", 
      "lng": "25.570066", 
      "population": "1021000"
  }, 
  {
      "city": "Pietermaritzburg", 
      "admin": "KwaZulu Natal", 
      "country": "South Africa", 
      "population_proper": "490951", 
      "iso2": "ZA", 
      "capital": "admin", 
      "lat": "-29.616785", 
      "lng": "30.39278", 
      "population": "750845"
  }, 
  {
      "city": "Bloemfontein", 
      "admin": "Free State", 
      "country": "South Africa", 
      "population_proper": "456669", 
      "iso2": "ZA", 
      "capital": "primary", 
      "lat": "-29.121065", 
      "lng": "26.214003", 
      "population": "463064"
  }, 
  {
      "city": "Welkom", 
      "admin": "Free State", 
      "country": "South Africa", 
      "population_proper": "126079", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-27.986442", 
      "lng": "26.706612", 
      "population": "431944"
  }, 
  {
      "city": "Nelspruit", 
      "admin": "Mpumalanga", 
      "country": "South Africa", 
      "population_proper": "21540", 
      "iso2": "ZA", 
      "capital": "admin", 
      "lat": "-25.474482", 
      "lng": "30.970333", 
      "population": "348138"
  }, 
  {
      "city": "East London", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "338627", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.011051", 
      "lng": "27.910049", 
      "population": "338627"
  }, 
  {
      "city": "Thohoyandou", 
      "admin": "Limpopo", 
      "country": "South Africa", 
      "population_proper": "44046", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-22.945642", 
      "lng": "30.484972", 
      "population": "269707"
  }, 
  {
      "city": "Springs", 
      "admin": "Gauteng", 
      "country": "South Africa", 
      "population_proper": "186394", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.258374", 
      "lng": "28.47173", 
      "population": "236083"
  }, 
  {
      "city": "Uitenhage", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "206766", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.75757", 
      "lng": "25.397099", 
      "population": "228912"
  }, 
  {
      "city": "Polokwane", 
      "admin": "Limpopo", 
      "country": "South Africa", 
      "population_proper": "123749", 
      "iso2": "ZA", 
      "capital": "admin", 
      "lat": "-23.904485", 
      "lng": "29.468851", 
      "population": "220045"
  }, 
  {
      "city": "Paarl", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "131718", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.733781", 
      "lng": "18.975228", 
      "population": "187865"
  }, 
  {
      "city": "Klerksdorp", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "147804", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.852128", 
      "lng": "26.666719", 
      "population": "178921"
  }, 
  {
      "city": "George", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "113248", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.963", 
      "lng": "22.461727", 
      "population": "174582"
  }, 
  {
      "city": "Rustenburg", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "124064", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-25.667562", 
      "lng": "27.242079", 
      "population": "165976"
  }, 
  {
      "city": "Kimberley", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "142089", 
      "iso2": "ZA", 
      "capital": "admin", 
      "lat": "-28.732262", 
      "lng": "24.762315", 
      "population": "165264"
  }, 
  {
      "city": "Bhisho", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "137287", 
      "iso2": "ZA", 
      "capital": "admin", 
      "lat": "-32.847212", 
      "lng": "27.442179", 
      "population": "160997"
  }, 
  {
      "city": "Middelburg", 
      "admin": "Mpumalanga", 
      "country": "South Africa", 
      "population_proper": "93790", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-25.775071", 
      "lng": "29.464821", 
      "population": "154706"
  }, 
  {
      "city": "Vryheid", 
      "admin": "KwaZulu Natal", 
      "country": "South Africa", 
      "population_proper": "66717", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-27.76952", 
      "lng": "30.791653", 
      "population": "150012"
  }, 
  {
      "city": "Umtata", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "78663", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-31.588926", 
      "lng": "28.784431", 
      "population": "137772"
  }, 
  {
      "city": "Worcester", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "90803", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.64651", 
      "lng": "19.448523", 
      "population": "127597"
  }, 
  {
      "city": "Potchefstroom", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "83814", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.716667", 
      "lng": "27.1", 
      "population": "123669"
  }, 
  {
      "city": "Brits", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "39947", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-25.634731", 
      "lng": "27.780224", 
      "population": "122497"
  }, 
  {
      "city": "Queenstown", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "87240", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-31.897563", 
      "lng": "26.875329", 
      "population": "105309"
  }, 
  {
      "city": "Mmabatho", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "76754", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-25.85", 
      "lng": "25.633333", 
      "population": "104428"
  }, 
  {
      "city": "Kroonstad", 
      "admin": "Free State", 
      "country": "South Africa", 
      "population_proper": "72835", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-27.65036", 
      "lng": "27.234879", 
      "population": "103992"
  }, 
  {
      "city": "Bethal", 
      "admin": "Mpumalanga", 
      "country": "South Africa", 
      "population_proper": "90450", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.457937", 
      "lng": "29.465534", 
      "population": "101919"
  }, 
  {
      "city": "Grahamstown", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "49083", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.304216", 
      "lng": "26.53276", 
      "population": "91548"
  }, 
  {
      "city": "Bethlehem", 
      "admin": "Free State", 
      "country": "South Africa", 
      "population_proper": "49092", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-28.230779", 
      "lng": "28.307071", 
      "population": "83654"
  }, 
  {
      "city": "Oudtshoorn", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "47998", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.592343", 
      "lng": "22.205482", 
      "population": "76708"
  }, 
  {
      "city": "Standerton", 
      "admin": "Mpumalanga", 
      "country": "South Africa", 
      "population_proper": "18093", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.933655", 
      "lng": "29.241518", 
      "population": "74021"
  }, 
  {
      "city": "Upington", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "52799", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-28.447758", 
      "lng": "21.256121", 
      "population": "71373"
  }, 
  {
      "city": "Saldanha", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "6654", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.01167", 
      "lng": "17.944202", 
      "population": "68284"
  }, 
  {
      "city": "Tzaneen", 
      "admin": "Limpopo", 
      "country": "South Africa", 
      "population_proper": "16954", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-23.833222", 
      "lng": "30.163506", 
      "population": "67245"
  }, 
  {
      "city": "Knysna", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "4668", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-34.036643", 
      "lng": "23.049704", 
      "population": "63106"
  }, 
  {
      "city": "Graaff-Reinet", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "3021", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-32.25", 
      "lng": "24.55", 
      "population": "62896"
  }, 
  {
      "city": "Port Shepstone", 
      "admin": "KwaZulu Natal", 
      "country": "South Africa", 
      "population_proper": "21858", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-30.741369", 
      "lng": "30.454992", 
      "population": "52793"
  }, 
  {
      "city": "Vryburg", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "13590", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-26.956588", 
      "lng": "24.728403", 
      "population": "49588"
  }, 
  {
      "city": "Ladysmith", 
      "admin": "KwaZulu Natal", 
      "country": "South Africa", 
      "population_proper": "47375", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-28.553914", 
      "lng": "29.782697", 
      "population": "47375"
  }, 
  {
      "city": "Beaufort West", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "11404", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-32.356708", 
      "lng": "22.582947", 
      "population": "44737"
  }, 
  {
      "city": "Aliwal North", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "8410", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-30.693664", 
      "lng": "26.711407", 
      "population": "44436"
  }, 
  {
      "city": "Volksrust", 
      "admin": "Mpumalanga", 
      "country": "South Africa", 
      "population_proper": "7411", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-27.365415", 
      "lng": "29.881752", 
      "population": "43378"
  }, 
  {
      "city": "Lebowakgomo", 
      "admin": "Limpopo", 
      "country": "South Africa", 
      "population_proper": "397", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-24.2", 
      "lng": "29.5", 
      "population": "33308"
  }, 
  {
      "city": "Cradock", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "32898", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-32.164221", 
      "lng": "25.619176", 
      "population": "32898"
  }, 
  {
      "city": "De Aar", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "5021", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-30.649657", 
      "lng": "24.012295", 
      "population": "32318"
  }, 
  {
      "city": "Hermanus", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "7396", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-34.418696", 
      "lng": "19.234464", 
      "population": "25153"
  }, 
  {
      "city": "Ulundi", 
      "admin": "KwaZulu Natal", 
      "country": "South Africa", 
      "population_proper": "5581", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-28.335233", 
      "lng": "31.416172", 
      "population": "20753"
  }, 
  {
      "city": "Komatipoort", 
      "admin": "Mpumalanga", 
      "country": "South Africa", 
      "population_proper": "159", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-25.433205", 
      "lng": "31.954777", 
      "population": "20508"
  }, 
  {
      "city": "Messina", 
      "admin": "Limpopo", 
      "country": "South Africa", 
      "population_proper": "3505", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-22.351308", 
      "lng": "30.039597", 
      "population": "20191"
  }, 
  {
      "city": "Middelburg", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "3764", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-31.492849", 
      "lng": "25.00633", 
      "population": "18164"
  }, 
  {
      "city": "Port Alfred", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "795", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-33.590572", 
      "lng": "26.891037", 
      "population": "17959"
  }, 
  {
      "city": "Bloemhof", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "4203", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-27.646855", 
      "lng": "25.606973", 
      "population": "17122"
  }, 
  {
      "city": "Mossel Bay", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "16743", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-34.183067", 
      "lng": "22.146048", 
      "population": "16743"
  }, 
  {
      "city": "Bredasdorp", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "1970", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-34.532154", 
      "lng": "20.040308", 
      "population": "14936"
  }, 
  {
      "city": "Swellendam", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "4234", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-34.022624", 
      "lng": "20.441706", 
      "population": "13674"
  }, 
  {
      "city": "Colesberg", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "2559", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-30.719994", 
      "lng": "25.097185", 
      "population": "12423"
  }, 
  {
      "city": "Brandfort", 
      "admin": "Free State", 
      "country": "South Africa", 
      "population_proper": "289", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-28.700083", 
      "lng": "26.459684", 
      "population": "12091"
  }, 
  {
      "city": "Prieska", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "4045", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-29.664088", 
      "lng": "22.747421", 
      "population": "11236"
  }, 
  {
      "city": "Springbok", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "2809", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-29.664336", 
      "lng": "17.886495", 
      "population": "10438"
  }, 
  {
      "city": "Kuruman", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "9093", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-27.452402", 
      "lng": "23.432456", 
      "population": "10006"
  }, 
  {
      "city": "Port Saint John\u2019s", 
      "admin": "Eastern Cape", 
      "country": "South Africa", 
      "population_proper": "5939", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-31.622911", 
      "lng": "29.544772", 
      "population": "5939"
  }, 
  {
      "city": "Carnarvon", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "5785", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-30.968266", 
      "lng": "22.133026", 
      "population": "5785"
  }, 
  {
      "city": "Pofadder", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "4220", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-29.128299", 
      "lng": "19.394915", 
      "population": "4220"
  }, 
  {
      "city": "Vanrhynsdorp", 
      "admin": "Western Cape", 
      "country": "South Africa", 
      "population_proper": "3331", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-31.608885", 
      "lng": "18.739679", 
      "population": "3331"
  }, 
  {
      "city": "Alexander Bay", 
      "admin": "Northern Cape", 
      "country": "South Africa", 
      "population_proper": "1452", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-28.596078", 
      "lng": "16.485355", 
      "population": "1500"
  }, 
  {
      "city": "Ubombo", 
      "admin": "KwaZulu Natal", 
      "country": "South Africa", 
      "population_proper": "564", 
      "iso2": "ZA", 
      "capital": "", 
      "lat": "-27.563673", 
      "lng": "32.085699", 
      "population": "564"
  }, 
  {
      "city": "Mahikeng", 
      "admin": "North West", 
      "country": "South Africa", 
      "population_proper": "", 
      "iso2": "ZA", 
      "capital": "admin", 
      "lat": "-25.86522", 
      "lng": "25.644213", 
      "population": ""
  }
];
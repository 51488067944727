import React  from "react";
import Modal from 'react-modal';
import Hamburger from './hamburger';
import Menu from './menu';

const customStyles: any = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: 9999
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    border                : 'none',
    backgroundColor: 'rgba(255, 255, 255, 0)',
  }
};

const Navigation: React.FC = () => {

  const [modalIsOpen, setIsOpen] = React.useState(false);
  
  const toggleModal = () => {
    setIsOpen(!modalIsOpen);
  }

  return (
    <>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          style={customStyles}
          ariaHideApp={false}
        >
          <Menu toggleNavigtion={toggleModal} />
      </Modal>
      <Hamburger toggleNavigtion={toggleModal} visibility={modalIsOpen} />
    </>
  )

}

export default Navigation;
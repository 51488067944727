import React, {CSSProperties} from "react";
import {Navigation} from '../index';
import {dbInitAndSetSource} from '../../utils/dbinit';

const headerStyle: CSSProperties = {
    'display': 'block',
    'background': '#31b2ed',
    'padding': '6px 22px'
}

const imageStyle: CSSProperties = {
  maxWidth: '220px',
  padding:'1rem 0'
}
const Header: React.FC = () => {

  dbInitAndSetSource();

  return (
    <div style={headerStyle}>
      <img src="/logo.png" style={imageStyle} />
      <Navigation />
    </div>
  )

}

export default Header;
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  toggleNavigtion: any
}

const Menu: React.FC<Props> = ({toggleNavigtion}) => {

  return (
    <div className="menu">
      <nav>
        <ul>
          {/* <li>
            <Link onClick={toggleNavigtion} to="/">Home</Link>
          </li> */}
          <li>
            <Link onClick={toggleNavigtion} to="/onboarding">Application Form</Link>
          </li>
          <li>
            <Link onClick={toggleNavigtion} to="/kit">Scan Your Kit</Link>
          </li>
        </ul>
      </nav>
    </div>
  )

}

export default Menu;
import React from "react";

interface Props {
  toggleNavigtion: any,
  visibility: boolean
}

const Hamburger: React.FC<Props> = ({toggleNavigtion, visibility}) => {

  return (
    <div onClick={toggleNavigtion} className={visibility ? "burger-menu open" : "burger-menu"}>
      <div className="bar1" key="b1" />
      <div className="bar2" key="b2" />
      <div className="bar3" key="b3" />
    </div>
  )

}

export default Hamburger;
import {geodata} from './geodata';
/**
* Time/Date Helper functions
*/

export const today = new Date(), month = today.getMonth() +1;
export const currentDate  = today.getFullYear() + '-' + (month < 10 ? '0' + month : month )  + '-' + today.getDate();

export const validateNumbersOnly = (input: any) => {
  //console.log('validateNumbersOnly Function: ', input);
  const regex = /^([0-9]+)$/;
  return regex.test(input);
}

export const validateIdNumber = (input: any) => {
  //console.log('validateNumbersOnly Function: ', input);
  const regex = /^([0-9]+)$/;
  return regex.test(input);
}

export const validateMobileNumber = (input: any) => {
  //console.log('validateMobileNumber Function: ', input);
  const regex = /^[0-9]{9}$/;
  //const regex = /^27[0-9]{9}$/;
  return regex.test(input);
}

export const validateEmailAddress = (input: any) => {
  //console.log('validateEmailAddress Function: ', input);
  const regex = /\S+@\S+\.\S+/;
  return regex.test(input);
}

export const validateURL = (input: string) => {
  
  let url;
  
  try {
    url = new URL(input);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const isEmptyObject = (obj: object) =>{
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Sort Geo data by province and cities within that province
 */
export const geodataCityByProvince = () => {

  let provinces: any = {};
  const regions = Object.keys(geodata);
  
  regions.map( (region: any) => {

    const admin: any = geodata[region].admin;
    const city: any = geodata[region].city

    if( Array.isArray( provinces[admin] ) ) {
      provinces[admin].push(city);
    }
    else {
      provinces[admin] = [];
      provinces[admin].push(city);
    }

    return false;

  });

  return provinces;

}

/**
 * If mm or cm these need to be converted to meters (ie. 165 needs to be 1.65)
 * @param weight in Kilograms
 * @param height in Meters
 */
export const bmiCalculator = (weight: number, height: number) => {
  return ( weight / (height * height) ).toFixed(2);
}
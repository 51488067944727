import React, {CSSProperties} from "react";

const footerStyle: CSSProperties = {
  display   : 'none',
  position  : 'fixed',
  bottom    : '-2rem',
  zIndex    : 999,
  maxHeight   : '30vh'
}

const Footer: React.FC = () => {

  return (
    <div style={footerStyle}>
      <img src="/footer.png" style={{'maxWidth' : '100%'}} alt='SABMR, Saving lives!' />
    </div>
  );

}

export default Footer;
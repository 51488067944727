import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import {Header, Footer} from './components';
import {Welcome, Home, Onboarding, Kit} from './pages';

import './App.scss';

export default function App() {

  const offlineStatus = !navigator.onLine;
  const [offline, setOffline] = React.useState(offlineStatus);
  
  window.addEventListener('online', () => {
    setOffline(false);
  });
  
  window.addEventListener('offline', () => {
    setOffline(true);
  });

  return (
    <Router>
      <div>
        
        <Header />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
           
          <Route path="/welcome">
            <Welcome />
          </Route>

          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/onboarding">
            <Onboarding offline={offline} />
          </Route>

          <Route path="/kit">
            <Kit offline={offline} />
          </Route>

        </Switch>

        <Footer />

      </div>
    </Router>
  );
}
import React  from "react";

interface Props {
  heading?: string,
  message: string
}

const WarningNotice: React.FC<Props> = ({heading, message}) => {

  return (
    <div style={{background: '#ffbf00', padding: '0.5rem', borderRadius: '0.5rem', margin: '0 0 1rem 0'}}>
      <div style={{display: 'flex', alignItems: 'center', margin: '0 0 0.5rem 0'}}>
        <img src='/warning.svg' style={{width: '2rem'}} alt='' />
        {
          heading &&
            <h5 style={{margin: '0 0 0 0.5rem'}}>{heading}</h5>
        }
        {
          !heading &&
            <p style={{fontSize: '0.75rem', margin: '0 0 0 0.5rem'}}>{message}</p>
        }
      </div>
      {
        heading &&
          <p style={{fontSize: '0.75rem', margin: 0}}>{message}</p>
      }
    </div>
  )

}

export default WarningNotice;
import React from "react";
import {useHistory} from "react-router-dom";
import {OnboardingForm} from '../components';
import {ApplicantFormModel} from '../models';
import OfflineNotice from '../components/ui/notices/offline';

import apiService from '../services/apiService';
import {db} from '../utils/database';

interface Props {
  offline?: boolean
}

const Onboarding: React.FC<Props> = ({offline}) => {

  let history = useHistory();

  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [applicant, setApplicant] = React.useState(false);
  const [qualified, setQualified] = React.useState(false);
  const [source, setSource] = React.useState({});

  const fetchDbRowData = async () => {

    await db.applicant.get({id:1}, (row)=>{

      // wait for the db to insert its first row and keep retrying until it does
      if(row){

        if(row.source){
          setSource(row.source)
        }

        if(row.drm_code){
          setApplicant(true)
        }

        if(row.qualified){
          setQualified(row.qualified === 'true')
        }
      }
      else {
        fetchDbRowData()
      }

    });
  }
  
  React.useEffect( () => {
    fetchDbRowData()
  }, []);

  const handleSubmit = async (data: ApplicantFormModel) => {

    setFormSubmitting(true);

    //console.log('Form was submitted with the following data:'); //<------ testing purposes only, can be removed!
    //console.log(data); //<------ testing purposes only, can be removed!

    // prefix mobile number
    if(data.mobileNumber){
      data.mobileNumber = parseInt(`27${data.mobileNumber}`);
    }

    const updatedData = {...data, ...source, };
    const response = await apiService.addApplicantDRM(updatedData);
    setFormSubmitting(false);

    if(response.status === 200){
      
      if(response.data.status === 'error'){
        alert(response.data.errors);
        return false;
      }

      if(response.data.status === 'success'){
        
        const recruitment_id = response.data.recruitment_id;
        const qualified = response.data.qualified;
        
        setApplicant(true);
        setQualified(qualified);

        db.transaction('rw', db.applicant, async() => {
          db.applicant.update(1, {
            drm_code: recruitment_id
          });
        });

        // console.log('success -> recruitment_id: ', recruitment_id); <------ testing purposes only, can be removed!
        
        if(recruitment_id && qualified){
          history.push('/kit');
          return;
        }

      }
    }

    if(response.status === 500){
      alert('Something went wrong, please try again or contact the SABMR!');
    }
    
  }

  const noticeStyle: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 16rem)',
    position: 'absolute',
    flexDirection: 'column',
    textAlign: 'center',
    width: '90%'
}

  return (
    <div style={{width: '90%', margin: '2rem auto'}}>

      {
        offline &&
          <OfflineNotice message='your application will not be lost but you will only be able to submit it once you have internet connectivity.' />
      }

      {
        !applicant &&
          <div>
            <h5 style={{'margin': '2vh 0'}}>Donor Recruitment Drive</h5>
            <p style={{fontSize: '0.75rem'}}>Please complete the form below. If your application is successful you will be issued with a buccal swab testing kit.</p>
            <hr />
            <OnboardingForm formSubmissionHandler={handleSubmit} formSubmitting={formSubmitting} offline={offline} />
          </div>
      }
      
      {
        applicant && qualified &&
          <div style={{
            ...noticeStyle,
            height: offline ? 'calc(80vh - 16rem)' :'calc(100vh - 16rem)'
            }}
          >
            <i className="fas fa-check-circle" style={{fontSize: '5rem', padding: '0 0 1rem', color: '#8bc34a'}}></i>
            <h5 style={{'margin': '2vh 0'}}>Congratulations</h5>
            <p style={{fontSize: '0.75rem'}}>Your application was submitted successfully.</p>
        </div>
      }

      {
        applicant && !qualified &&
          <div style={{
            ...noticeStyle,
            height: offline ? 'calc(80vh - 16rem)' :'calc(100vh - 16rem)'
            }}
          >
            <i className="fas fa-exclamation-circle" style={{fontSize: '5rem', padding: '0 0 2rem', color: 'rgb(241 85 49)'}}></i>
            <p style={{fontSize: '0.75rem'}}>Thank you for joining us during our Recruitment Drive today. We value your time and effort!</p>
            <p style={{fontSize: '0.75rem'}}>Unfortunately the brief health questionnaire indicated a possible risk that might make you not eligible to join the SABMR as a bone marrow stem cell donor. If would like to discuss this further, please contact us at <a href="mailto:donors@sabmr.co.za">donors@sabmr.co.za</a> or telephonically on <a href="tel:+27 21 447 8638">021 447 8638</a>. we will however not be able to assist you with your application today.</p>
            <p style={{fontSize: '0.75rem'}}>Kindly spread the word and share our information throughout South Africa.</p>
            <p style={{fontSize: '0.75rem'}}>Remember: You could still be part of someone’s tomorrow!</p>
        </div>
      }
      
    </div>
  );

}

export default Onboarding;
import Dexie from 'dexie';
import {ApplicantModel} from '../models';

interface App {
  id?: number;
  version: string;
}

interface Applicant extends ApplicantModel {
  source?: any;
  regret?: string | undefined;
  id?: number;
}

class ApplicationDatabase extends Dexie {
    
  public applicant: Dexie.Table<Applicant, number>; // id is number in this case
  public app: Dexie.Table<App, number>; // id is number in this case

  public constructor() {
      
    super("ApplicationDatabase");
      
    this.version(1).stores({
      app: "++id, version",
      applicant: "++id,firstName,lastName,mobileNumber,emailAddress,birthday,weight,height,gender,ethnicity,age,province,city,agegroup,diseases,bmi,qualified,drm_code,kit_code,source,regret"
    });

    this.app = this.table("app");
    this.applicant = this.table("applicant");
  }
  
}

export const db = new ApplicationDatabase();
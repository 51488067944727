import React from "react";

const Home: React.FC = () => {

  return (
    <div style={{width: '90%', margin: '2rem auto'}}>
      <h2 style={{'margin': '30vh 0 1rem', 'textAlign': 'center'}}>Home</h2>
      <div style={{fontSize: '0.75rem', textAlign: 'center'}}>Keep checking in... we will soon be adding some new and exciting features to keep you updated with the latest news, events and success stories!</div>
    </div>
  );

}

export default Home;
import React  from "react";

interface Props {
  text: string,
  customStyles?: Object
}

const Circle: React.FC<Props> = ({text, customStyles}) => {

  const styles = {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    background: 'red',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600
  }

  return <div style={{ ...styles, ...customStyles }}>{text}</div>

}

export default Circle;
import axios, { AxiosInstance } from 'axios';
import {ApplicantFormModel, ApplicantKitLinkModel} from '../models';

class ApiService {
	
	private api: AxiosInstance

	constructor() {
		this.api = axios.create({
      withCredentials: true,
      baseURL: process.env.REACT_APP_DRM_API || '/',
      headers: {
        'Content-type' : 'application/json', 
        'Accept' : 'application/json'
      }
		});
	}

	handleErrorResponse = (error: any) => {
		if (error.response) {
			return error.response.status;
		}
		return 500;
	}

	addApplicantDRM = (applicant: ApplicantFormModel) => {
    
    let formData = new FormData();
    const fields = Object.keys(applicant) as Array<keyof ApplicantFormModel>;

    fields.map( (field) => {
      const value: any = applicant[field];
      formData.append(field, value);
      return false;
    });

    // Append token as part of the payload
    const token = process.env.REACT_APP_DRM_API_TOKEN ? process.env.REACT_APP_DRM_API_TOKEN : '';
    formData.append('token', token);
    formData.append('platform', 'app');
    
    const config = {
      headers: { 
        'content-type': 'multipart/form-data',
        'API-VERSION' : 1.1
      }
    }

    return this.api.post<any>(`api/applicants-new.php`, formData, config).catch(error => this.handleErrorResponse(error));
  }

	updateApplicantWithKit = (applicantKit: ApplicantKitLinkModel) => {
    
    let formData = new FormData();
    const fields = Object.keys(applicantKit) as Array<keyof ApplicantKitLinkModel>;

    fields.map( (field) => {
      const value: any = applicantKit[field];
      formData.append(field, value);
      return false;
    });

    // Append token as part of the payload
    const token = process.env.REACT_APP_DRM_API_TOKEN ? process.env.REACT_APP_DRM_API_TOKEN : '';
    formData.append('token', token);

    const config = {     
      headers: { 
        'content-type': 'multipart/form-data',
        'API-VERSION' : 1.1
      }
    }
    
    return this.api.post<any>(`api/appkit-link.php`, formData, config).catch(error => this.handleErrorResponse(error));
  }
	
}

export default new ApiService();
import React  from "react";

interface Props {
  message: string
}

const OfflineNotice: React.FC<Props> = ({message}) => {

  return (
    <div style={{background: '#ffbf00', padding: '0.5rem', borderRadius: '0.5rem'}}>
      <div style={{display: 'flex', alignItems: 'center', margin: '0 0 0.5rem 0'}}>
        <img src='/warning.svg' style={{width: '2rem'}} alt='' />
        <h5 style={{margin: '0 0 0 0.5rem'}}>You are currently offline</h5>
      </div>
      <p style={{fontSize: '0.75rem', margin: 0}}>{message}</p>
    </div>
  )

}

export default OfflineNotice;